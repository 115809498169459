/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  MailingListDto,
  MailingListPageDto,
  MailingListReferencePageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    MailingListDtoFromJSON,
    MailingListDtoToJSON,
    MailingListPageDtoFromJSON,
    MailingListPageDtoToJSON,
    MailingListReferencePageDtoFromJSON,
    MailingListReferencePageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateMailingListRequest {
    mailingListDto: MailingListDto;
}

export interface DeleteMailingListRequest {
    id: string;
}

export interface FetchMailingListRequest {
    id: string;
}

export interface FetchMailingListHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchMailingListReferencesRequest {
    pageable?: PageableDto;
    searchquery?: string;
}

export interface SearchMailingListsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateMailingListRequest {
    id: string;
    mailingListDto: MailingListDto;
}

/**
 * 
 */
export class MailingListApi extends runtime.BaseAPI {

    /**
     * Create a new mailing list.
     */
    async createMailingListRaw(requestParameters: CreateMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListDto>> {
        if (requestParameters['mailingListDto'] == null) {
            throw new runtime.RequiredError(
                'mailingListDto',
                'Required parameter "mailingListDto" was null or undefined when calling createMailingList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingListDtoToJSON(requestParameters['mailingListDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListDtoFromJSON(jsonValue));
    }

    /**
     * Create a new mailing list.
     */
    async createMailingList(requestParameters: CreateMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListDto> {
        const response = await this.createMailingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the mailing list.
     */
    async deleteMailingListRaw(requestParameters: DeleteMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailingList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the mailing list.
     */
    async deleteMailingList(requestParameters: DeleteMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingListRaw(requestParameters, initOverrides);
    }

    /**
     * Get the mailing list business object.
     */
    async fetchMailingListRaw(requestParameters: FetchMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListDtoFromJSON(jsonValue));
    }

    /**
     * Get the mailing list business object.
     */
    async fetchMailingList(requestParameters: FetchMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListDto> {
        const response = await this.fetchMailingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the mailing list.
     */
    async fetchMailingListHistoryRaw(requestParameters: FetchMailingListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingListHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the mailing list.
     */
    async fetchMailingListHistory(requestParameters: FetchMailingListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingListHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailing list.
     */
    async searchMailingListReferencesRaw(requestParameters: SearchMailingListReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailing list.
     */
    async searchMailingListReferences(requestParameters: SearchMailingListReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListReferencePageDto> {
        const response = await this.searchMailingListReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailing lists.
     */
    async searchMailingListsRaw(requestParameters: SearchMailingListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailing lists.
     */
    async searchMailingLists(requestParameters: SearchMailingListsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListPageDto> {
        const response = await this.searchMailingListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new mailing list.
     */
    async templateMailingListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new mailing list.
     */
    async templateMailingList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListDto> {
        const response = await this.templateMailingListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing mailing list.
     */
    async updateMailingListRaw(requestParameters: UpdateMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingListDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailingList().'
            );
        }

        if (requestParameters['mailingListDto'] == null) {
            throw new runtime.RequiredError(
                'mailingListDto',
                'Required parameter "mailingListDto" was null or undefined when calling updateMailingList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailingListDtoToJSON(requestParameters['mailingListDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingListDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing mailing list.
     */
    async updateMailingList(requestParameters: UpdateMailingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingListDto> {
        const response = await this.updateMailingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
