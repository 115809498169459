import invariant from 'tiny-invariant';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { useMailingListPermissionClassGroupExtension } from './mailing-list-permissions';
import { MAILING_RECIPIENT_EXTENSION } from '../mailing/mailing-recipient-extension';
import { MAILING_TYPE_EXTENSION } from '../mailing/mailing-type-extension';
import { isMailingListRecipientLink } from './mailing-list-recipient/mailing-list-recipient-link';
import MailingListReference from './mailing-list-reference/mailing-list-reference';
import { createNewsletterMailing } from './newsletter-mailing/newsletter-mailing-extension';

export default function MailingListPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useMailingListPermissionClassGroupExtension);
  registry.register(MAILING_TYPE_EXTENSION, createNewsletterMailing());
  registry.register(MAILING_RECIPIENT_EXTENSION, {
    type: 'MAILING_LIST',
    viewer(link) {
      invariant(isMailingListRecipientLink(link), `Unexpected link type ${link.type}`);
      return <MailingListReference mailingList={link.linkedRecipient} />;
    },
    asString(link) {
      invariant(isMailingListRecipientLink(link), `Unexpected link type ${link.type}`);
      return link.linkedRecipient.title;
    },
  });
}
