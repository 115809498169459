/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  OfferDto,
  OfferPageDto,
  OfferViewerDto,
  OffersExportRequestDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    OfferDtoFromJSON,
    OfferDtoToJSON,
    OfferPageDtoFromJSON,
    OfferPageDtoToJSON,
    OfferViewerDtoFromJSON,
    OfferViewerDtoToJSON,
    OffersExportRequestDtoFromJSON,
    OffersExportRequestDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateOfferRequest {
    offerDto: OfferDto;
}

export interface ExportOffersRequest {
    offersExportRequestDto: OffersExportRequestDto;
    pageable?: PageableDto;
}

export interface FetchOfferRequest {
    id: string;
}

export interface FetchOfferHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface ReplaceOfferRequest {
    id: string;
    offerDto: OfferDto;
}

export interface SearchOffersRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateOfferRequest {
    id: string;
    offerDto: OfferDto;
}

/**
 * 
 */
export class OfferApi extends runtime.BaseAPI {

    /**
     * Create a new offer.
     */
    async createOfferRaw(requestParameters: CreateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['offerDto'] == null) {
            throw new runtime.RequiredError(
                'offerDto',
                'Required parameter "offerDto" was null or undefined when calling createOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferDtoToJSON(requestParameters['offerDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new offer.
     */
    async createOffer(requestParameters: CreateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOfferRaw(requestParameters, initOverrides);
    }

    /**
     * Export filtered offers.
     */
    async exportOffersRaw(requestParameters: ExportOffersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['offersExportRequestDto'] == null) {
            throw new runtime.RequiredError(
                'offersExportRequestDto',
                'Required parameter "offersExportRequestDto" was null or undefined when calling exportOffers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OffersExportRequestDtoToJSON(requestParameters['offersExportRequestDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export filtered offers.
     */
    async exportOffers(requestParameters: ExportOffersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportOffersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the offer.
     */
    async fetchOfferRaw(requestParameters: FetchOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferViewerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferViewerDtoFromJSON(jsonValue));
    }

    /**
     * Get the offer.
     */
    async fetchOffer(requestParameters: FetchOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferViewerDto> {
        const response = await this.fetchOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the offer.
     */
    async fetchOfferHistoryRaw(requestParameters: FetchOfferHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOfferHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the offer.
     */
    async fetchOfferHistory(requestParameters: FetchOfferHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchOfferHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Replace the existing offer.
     */
    async replaceOfferRaw(requestParameters: ReplaceOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling replaceOffer().'
            );
        }

        if (requestParameters['offerDto'] == null) {
            throw new runtime.RequiredError(
                'offerDto',
                'Required parameter "offerDto" was null or undefined when calling replaceOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferDtoToJSON(requestParameters['offerDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Replace the existing offer.
     */
    async replaceOffer(requestParameters: ReplaceOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.replaceOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for offers.
     */
    async searchOffersRaw(requestParameters: SearchOffersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for offers.
     */
    async searchOffers(requestParameters: SearchOffersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferPageDto> {
        const response = await this.searchOffersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new offer.
     */
    async templateOfferRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new offer.
     */
    async templateOffer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferDto> {
        const response = await this.templateOfferRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing offer.
     */
    async updateOfferRaw(requestParameters: UpdateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOffer().'
            );
        }

        if (requestParameters['offerDto'] == null) {
            throw new runtime.RequiredError(
                'offerDto',
                'Required parameter "offerDto" was null or undefined when calling updateOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OfferDtoToJSON(requestParameters['offerDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing offer.
     */
    async updateOffer(requestParameters: UpdateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOfferRaw(requestParameters, initOverrides);
    }

}
