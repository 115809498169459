/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingItemsViewerDto } from './AccountingItemsViewerDto';
import {
    AccountingItemsViewerDtoFromJSON,
    AccountingItemsViewerDtoFromJSONTyped,
    AccountingItemsViewerDtoToJSON,
} from './AccountingItemsViewerDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { OfferPaymentTargetDto } from './OfferPaymentTargetDto';
import {
    OfferPaymentTargetDtoFromJSON,
    OfferPaymentTargetDtoFromJSONTyped,
    OfferPaymentTargetDtoToJSON,
} from './OfferPaymentTargetDto';
import type { OfferReferenceDto } from './OfferReferenceDto';
import {
    OfferReferenceDtoFromJSON,
    OfferReferenceDtoFromJSONTyped,
    OfferReferenceDtoToJSON,
} from './OfferReferenceDto';
import type { OfferStatusDto } from './OfferStatusDto';
import {
    OfferStatusDtoFromJSON,
    OfferStatusDtoFromJSONTyped,
    OfferStatusDtoToJSON,
} from './OfferStatusDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface OfferViewerDto
 */
export interface OfferViewerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OfferViewerDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof OfferViewerDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof OfferViewerDto
     */
    offerNumber?: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof OfferViewerDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof OfferViewerDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof OfferViewerDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {Array<AccountingItemsViewerDto>}
     * @memberof OfferViewerDto
     */
    items: Array<AccountingItemsViewerDto>;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof OfferViewerDto
     */
    validityPeriod: ClosedDateRangeDto;
    /**
     * 
     * @type {OfferReferenceDto}
     * @memberof OfferViewerDto
     */
    originalOffer?: OfferReferenceDto;
    /**
     * 
     * @type {OfferReferenceDto}
     * @memberof OfferViewerDto
     */
    updatedOffer?: OfferReferenceDto;
    /**
     * 
     * @type {OfferStatusDto}
     * @memberof OfferViewerDto
     */
    status: OfferStatusDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof OfferViewerDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof OfferViewerDto
     */
    offerDate: string;
    /**
     * 
     * @type {Array<OfferPaymentTargetDto>}
     * @memberof OfferViewerDto
     */
    paymentPlan: Array<OfferPaymentTargetDto>;
}

/**
 * Check if a given object implements the OfferViewerDto interface.
 */
export function instanceOfOfferViewerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('items' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    if (!('status' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('offerDate' in value)) return false;
    if (!('paymentPlan' in value)) return false;
    return true;
}

export function OfferViewerDtoFromJSON(json: any): OfferViewerDto {
    return OfferViewerDtoFromJSONTyped(json, false);
}

export function OfferViewerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferViewerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'offerNumber': json['offerNumber'] == null ? undefined : json['offerNumber'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'items': ((json['items'] as Array<any>).map(AccountingItemsViewerDtoFromJSON)),
        'validityPeriod': ClosedDateRangeDtoFromJSON(json['validityPeriod']),
        'originalOffer': json['originalOffer'] == null ? undefined : OfferReferenceDtoFromJSON(json['originalOffer']),
        'updatedOffer': json['updatedOffer'] == null ? undefined : OfferReferenceDtoFromJSON(json['updatedOffer']),
        'status': OfferStatusDtoFromJSON(json['status']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'offerDate': json['offerDate'],
        'paymentPlan': ((json['paymentPlan'] as Array<any>).map(OfferPaymentTargetDtoFromJSON)),
    };
}

export function OfferViewerDtoToJSON(value?: OfferViewerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'offerNumber': value['offerNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'items': ((value['items'] as Array<any>).map(AccountingItemsViewerDtoToJSON)),
        'validityPeriod': ClosedDateRangeDtoToJSON(value['validityPeriod']),
        'originalOffer': OfferReferenceDtoToJSON(value['originalOffer']),
        'updatedOffer': OfferReferenceDtoToJSON(value['updatedOffer']),
        'status': OfferStatusDtoToJSON(value['status']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'offerDate': value['offerDate'],
        'paymentPlan': ((value['paymentPlan'] as Array<any>).map(OfferPaymentTargetDtoToJSON)),
    };
}

